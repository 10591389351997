@media(max-width: 1050px) {
    .chat__container {
        width: 90%;
        margin: auto;
    }
}

@media(max-width: 560px) {
    .chat__input-container input {
        min-width: 300px;
    }
}


.chatbot__iniciar-button {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbot__iniciar-button button {
    color: white;
    background-color: #171717;
    border: 1px solid rgb(31, 31, 31);
    border-radius: 10px;
    padding: 10px 15px;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.3s;
}

.chatbot__iniciar-button button:hover {
    background: #0080c9;
    color: #171717;
    transform: scale(1.05);
    border: 1px solid rgb(210, 254, 210);
}

.chat__container {
    background: linear-gradient(45deg, #858585 0%, #b9b9b9 100%);
    box-shadow: 2px 2px 8px white;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 15px;
    min-width: 380px;

}

.chat__input-container {
    display: grid;
    grid-template-columns: 1fr auto auto;
}

.chat__input-container input {
    width: auto;
    min-width: 200px;
    height: 50px;
    margin-bottom: 5px;
    margin-left: 5px;
    padding-left: 10px;
    border-radius: 6px;
}

.chat__input-container .btn-mrln {
    width: 80px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat__input-container .btn-mrln:focus {
    background-color: #171717;
}

.chat__input-container .btn-mrln:hover {
    background-color: #171717;
}

div p {
    color: #858585;
}

.chatbot-p {
    color: #171717;
}

.chat__input-container button {
    width: 10%;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #171717;
    border-radius: 6px;
}

.chat__input-container button:hover {
    background: #0080c9;
}

.chat__content {
    padding: 5px;
    padding-top: 10px;
}

.chatbot__message {
    display: flex;
    justify-content: flex-start;
}

.chat__bubble {
    border-radius: 10px;
    padding: 5px 10px;
    margin: 3px;
    color: rgb(78, 78, 78);
}

.chatbot__message span {
    /* background-color: #61c2fb; */
    background: #0080c9;
    box-shadow: 3px 3px 6px;
    max-width: 80%;
    text-align: left;
    color: #171717;
}

.message__container {
    padding-bottom: 3px;
}

.user__message {
    display: flex;
    justify-content: flex-end;
}

.user__message span {
    background: #31adf5;
    box-shadow: 3px 3px 6px;
    color: #171717;
    max-width: 80%;
    text-align: left;
}

p span {
    color: #0080c9;
}