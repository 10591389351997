/* Reglas para la impresión */
@media print {

    .table,
    .table__body {
        overflow: visible;
        height: auto !important;
        width: auto !important;
    }
}

.centrar__tables {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* Estilo principal para la tabla */
main.table {
    width: 82vw;
    height: 80vh;
    min-height: 650px;
    background-color: #1a1a1a;
    backdrop-filter: blur(12px);
    box-shadow: 0 .4rem .8rem #0005;
    border-radius: .8rem;
    overflow: hidden;
}

.table__sale_details {
    width: 100%;
    color: #1a1a1a;
    background-color: #1a1a1a;
    backdrop-filter: blur(12px);
    box-shadow: 0 .4rem .8rem #0005;
    border-radius: .8rem;
    overflow: hidden;
}


/* Cabecera de la tabla */
.table__header {
    width: 100%;
    height: 10%;
    background-color: #424242;
    padding: .8rem 1rem;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Cuerpo de la tabla */
.table__body {
    width: 95%;
    max-height: calc(89% - 1.6rem);
    background-color: #fffb;
    margin: .8rem auto;
    border-radius: .6rem;
    overflow: auto;
}

/* Estilos para las barras de desplazamiento */
.table__body::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background-color: #0004;
    visibility: hidden;
}

.table__body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

/* Estilos generales para la tabla */
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 1rem;
    text-align: center;
    height: 80px;
}

td img {
    width: 36px;
    height: 36px;
    margin-right: .5rem;
    border-radius: 50%;
    vertical-align: middle;
}

/* Estilos para las filas */
tbody tr:nth-child(even) {
    background-color: #988dfc13;
}

tbody tr {
    --delay: .1s;
    transition: .5s ease-in-out var(--delay), background-color 0s;
}

tbody tr.hide {
    opacity: 0;
    transform: translateX(100%);
}

tbody tr:hover {
    background-color: rgba(209, 220, 255, 0.342) !important;
}

tbody tr td,
tbody tr td p,
tbody tr td img {
    transition: .2s ease-in-out;
}

tbody tr.hide td,
tbody tr.hide td p {
    padding: 0;
    font: 0 / 0 sans-serif;
    transition: .2s ease-in-out .5s;
}

tbody tr.hide td img {
    width: 0;
    height: 0;
    transition: .2s ease-in-out .5s;
}

/* Estilos para los encabezados de la tabla */
thead th {
    position: sticky;
    top: 0;
    text-transform: capitalize;
}

.table__head {
    background: #9e9d9d !important;
}

thead th:hover {
    color: #049ee0;
    transition: 0.25s;
}

/* Adaptaciones para pantallas pequeñas */
@media (max-width: 1000px) {
    td:not(:first-of-type) {
        min-width: 12.1rem;
    }
}

@media (max-width: 500px) {
    main.table__sale_details {
        width: 350px;
    }

    main {
        font-size: 0.8em;
    }
}